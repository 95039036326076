import React from "react";
import { Link } from "gatsby";
import { motion, AnimatePresence } from "framer-motion";
import "./navbar.css";

const Navbar = () => {
  const [isOpen, setIsOpen] = React.useState(false);
  const [scroll, setScroll] = React.useState(false);


  const handleNavItemClick = () => {
    setIsOpen(false);
  };

  React.useEffect(() => {
    window.addEventListener("scroll", () => {
      if (window.scrollY > 50) {
        setScroll(true);
      } else {
        setScroll(false);
      }
    });
    return () => {
      window.removeEventListener("scroll", () => { });
    };
  }, []);

  return (
    <nav
      className={`fixed transition-colors duration-200 ease-in w-full z-50 top-0 text-green-600 ${scroll || isOpen ? "bg-white shadow-lg" : "bg-transparent"
        }`}
    >
      <div className="w-full container mx-auto flex flex-wrap items-center justify-between mt-0 py-2">
        <div className="pl-4 flex items-center">
          <Link to="/" className="text-4xl font-bold">Darat</Link>
        </div>
        <div className="block lg:hidden pr-4">
          <button
            onClick={() => setIsOpen(!isOpen)}
            className="px-3 py-2"
          >
            <div id="nav-icon3" className={`${isOpen ? "open" : null}`}>
              <span className="bg-green-600"></span>
              <span className="bg-green-600"></span>
              <span className="bg-green-600"></span>
              <span className="bg-green-600"></span>
            </div>
          </button>
        </div>
        <AnimatePresence>
          <motion.div
            className={`${isOpen ? `flex` : `hidden`
              } w-full items-center flex-grow lg:flex lg:items-center lg:w-auto mt-2 lg:mt-0 bg-white lg:bg-transparent text-black p-4 lg:p-0 z-20 lg:h-10 h-[50vh]`}
          >
            <ul className="flex flex-col lg:flex-row lg:flex text-2xl lg:text-base justify-end flex-1 items-center">
              <li className="mr-3 navbar-menu-item">
                <Link
                  className="inline-block transition-colors duration-150 ease-in py-2 px-4 text-green-600 no-underline hover:text-green-800"
                  to="/products"
                  activeClassName="text-green-800 font-bold"
                  onClick={handleNavItemClick}
                >
                  Products
                </Link>
              </li>
              <li className="mr-3 navbar-menu-item">
                <Link
                  className="inline-block transition-colors duration-150 ease-in py-2 px-4 text-green-600 no-underline hover:text-green-800"
                  to="/about"
                  activeClassName="text-green-800 font-bold"
                  onClick={handleNavItemClick}
                >
                  About Us
                </Link>
              </li>
              <li className="mr-3 navbar-menu-item">
                <Link
                  className="inline-block transition-colors duration-150 ease-in py-2 px-4 text-green-600 no-underline hover:text-green-800"
                  to="/contact"
                  activeClassName="text-green-800 font-bold"
                  onClick={handleNavItemClick}
                >
                  Contact Us
                </Link>
              </li>
            </ul>
          </motion.div>
        </AnimatePresence>
      </div>
    </nav>
  );
};

export default Navbar;
