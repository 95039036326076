import React from "react"
import { Link } from "gatsby"

const Footer = () => {
    const year = new Date().getFullYear()
    return (
        <footer className="bg-teal-900 pt-12 pb-3">
            <div className="container mx-auto px-6">
                <div className="flex flex-wrap -mx-4">
                    <div className="w-full md:w-1/3 px-4 mb-8">
                        <Link to="/" className="text-4xl font-bold text-green-400">Darat</Link>
                        <p className="text-gray-100 mb-4">Turning plastic waste into valuable resources</p>
                    </div>
                    <div className="w-full md:w-1/3 px-4 mb-8">
                        <h3 className="text-lg font-bold text-white mb-4">Important Links</h3>
                        <ul className="list-reset text-white">
                            <li className="mb-2"><Link className="text-white hover:text-gray-100" to="/">Home</Link></li>
                            <li className="mb-2"><Link className="text-white hover:text-gray-100" to="/about">About Us</Link></li>
                            <li className="mb-2"><Link className="text-white hover:text-gray-100" to="/products">Products</Link></li>
                            <li className="mb-2"><Link className="text-white hover:text-gray-100" to="#">Contact Ust</Link></li>
                        </ul>
                    </div>
                    <div className="w-full md:w-1/3 px-4 mb-8">
                        <h3 className="text-lg font-bold text-white mb-4">Contact Us</h3>
                        <ul className="list-reset text-white">
                            <li className="mb-2"><a className="text-white hover:text-gray-100" href="mailto:info@ourplasticrecyclingcompany.com">daratalsuwaiq@gmail.com</a></li>
                            <li className="mb-2"><a className="text-white hover:text-gray-100" href="tel:1234567890">+968 9570 0138</a></li>
                            <li className="mb-2"><a className="text-white hover:text-gray-100" href="tel:1234567890">+968 9570 0135</a></li>
                        </ul>
                    </div>
                </div>
                <div className="mt-8 border-t-2 border-gray-100 pt-4 text-center">
                    <p className="text-white text-sm">© {year} Darat Al Suwayq Trading Asso. All rights reserved.</p>
                </div>
            </div>
        </footer>
    )
}

export default Footer
