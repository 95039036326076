import React from "react";
import Navbar from "./navbar";
import Footer from "./footer";
import { AnimatePresence } from "framer-motion";

const Layout = ({ children }) => (
  <>
    <Navbar />
    <AnimatePresence mode="wait">
      {children}
    </AnimatePresence>
    <Footer />
  </>
);

export default Layout;
